import React from "react";
import styled from "styled-components";

const StyledCollapsibleItem = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 8px;

  border: 1px solid rgba(0,0,0,.125);

  .item-title  {
    display: flex;
    justify-content: space-between;

    font-family: 'RobotoBold';
    font-size: 13px;
    line-height: calc(1.42857rem * (13 / 16));
    text-decoration: none;
    color: #002346;
  }

  .item-collapsible {
    margin-top: 18px;

    span {
      font-family: 'RobotoRegular';
      font-size: 13px;
      line-height: calc(1.42857rem * (13 / 16));
      color: #002346;
    }

    ul {
      list-style: disc;
      padding-left: 32px;

      li {
        font-family: 'RobotoRegular';
        font-size: 13px;
        line-height: calc(1.42857rem * (13 / 16));
        color: #002346;
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {

  }
`;

function CollapsibleItem({ id, title, collapsibleContent }) {
  return (
    <StyledCollapsibleItem>
      <a
        data-bs-toggle="collapse"
        role="button"
        href={`#collapse-${id}`}
        aria-expanded="false"
        aria-controls={`collapse-${id}`}
        className="item-title collapsed"
      >
        {title}
      </a>
      <div id={`collapse-${id}`} className="item-collapsible collapse">
        <span>{collapsibleContent}</span>
      </div>
    </StyledCollapsibleItem>
  );
}

export default CollapsibleItem;
