import React from "react";
import styled from "styled-components";

import CollapsibleItem from "./CollapsibleItem";

export const StyledCollapsibleList = styled.div`

`;

function CollapsibleList({ items = [] }) {
  return (
    <StyledCollapsibleList>
      {items.map(({ id, ...restProps }) => (
        <CollapsibleItem key={id} id={id} {...restProps} />
      ))}
    </StyledCollapsibleList>
  );
}

export default CollapsibleList;
