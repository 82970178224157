import React from "react";
import styled from "styled-components";
import Link from "next/link";

import { hexToRGBA } from "../../../../utils/theme";
import ProductCard from "../../ProductCard";
import { useRouter } from "next/router";

const StyledBusinessEntitiesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .product-cards {
    display: flex;
    flex-wrap: wrap;

    width: 980px;

    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;

    background: ${hexToRGBA('#000000', 0.03)};
    
    .product-card-container {
      width: 50%;

      margin-bottom: 16px;

      &:nth-child(even) {
        padding-left: 8px;
      }

      &:nth-child(odd) {
        padding-right: 8px;
      }
    }
  }

  .learn-more-container {
    padding-top: 16px;

    span {
      font-family: 'MerriweatherRegular';
      color: #244f74;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .product-cards {
      width: 720px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .product-cards {
      width: 100%;

      .product-card-container {
        width: 100%;
        
        &:nth-child(even) {
          padding-left: 0;
        }

        &:nth-child(odd) {
          padding-right: 0;
        }
      }
    }
  }
`;
const StyledBusinessEntitiesSection2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .product-cards {
    display: flex;
    flex-wrap: wrap;

    width: 1024px;

    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;

    background: ${hexToRGBA('#000000', 0.25)};
    
    .product-card-container {
      width: 50%;

      margin-bottom: 16px;

      &:nth-child(even) {
        padding-left: 8px;
      }

      &:nth-child(odd) {
        padding-right: 8px;
      }
    }
  }

  .learn-more-container {
    padding-top: 16px;

    span {
      font-family: 'MerriweatherRegular';
      color: #244f74;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .product-cards {
      width: 720px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .product-cards {
      width: 100%;

      .product-card-container {
        width: 100%;
        
        &:nth-child(even) {
          padding-left: 0;
        }

        &:nth-child(odd) {
          padding-right: 0;
        }
      }
    }
  }
`;
function BusinessEntitiesSection({ products, onGoToDescriptionClick }) {
  const router = useRouter();
  if(router.asPath.includes("EINLLC2")) {
    return (
      <StyledBusinessEntitiesSection2>
        <div className="product-cards">
          {products.map(({ code, path, iconSrc, customerName, shortDescription, popular }) => (
            <Link key={code} href={`/products/${path}`} passHref>
              <div className="product-card-container">
                <ProductCard
                  id={code}
                  iconSrc={iconSrc}
                  title={customerName}
                  description={shortDescription}
                  popular={popular}
                />
              </div>
            </Link>
          ))}
        </div>
        <div style={{ marginBottom: "40px" }}></div>
      </StyledBusinessEntitiesSection2>
    );
  } else {
      return (
        <StyledBusinessEntitiesSection>
          <div className="product-cards">
            {products.map(({ code, path, iconSrc, customerName, shortDescription, popular }) => (
              <Link key={code} href={`/products/${path}`} passHref>
                <div className="product-card-container">
                  <ProductCard
                    id={code}
                    iconSrc={iconSrc}
                    title={customerName}
                    description={shortDescription}
                    popular={popular}
                  />
                </div>
              </Link>
            ))}
          </div>
            <div className="learn-more-container">
              <span onClick={onGoToDescriptionClick}>Learn more about legal entities</span> if you are not sure which type
              of legal structure you should choose
            </div>
        </StyledBusinessEntitiesSection>
      );
  }
}

export default BusinessEntitiesSection;
