import Link from "next/link";
import { config } from "../../../../_config";

const { email } = config;
export const FAQ_TERMS = [
  {
    id: 1,
    title: "Federal EIN",
    collapsibleContent: (
      <span>
        A federal EIN (FEIN) is a nine digit number issued by the IRS. 
        The EIN federal tax identification number or EIN federal identification number is used by the IRS to identify the tax accounts of employers.
      </span>
    ),
  },
  {
    id: 2,
    title: "Federal EIN number",
    collapsibleContent: (
      <span>
        A federal EIN number is a nine digit number issued by the IRS. 
        The federal EIN number is used by the IRS to identify the tax accounts of employers.
      </span>
    ),
  },
  {
    id: 3,
    title: "Federal Tax Identification number",
    collapsibleContent: (
      <span>
        A federal tax identification number is used by the IRS to identify business entities within the United States. 
        The federal tax identification number is also known as a federal ein number, EIN, employee identification number or employer ID number.
      </span>
    ),
  },
  {
    id: 4,
    title: "IRS SS4 Application",
    collapsibleContent: (
      <span>
        The IRS SS4 application is used to apply for a federal tax identification number / EIN. 
        The IRS SS4 application online can also be utilized as a method for applying for a employer identification number / EIN.
      </span>
    ),
  },
  {
    id: 5,
    title: "Employer Identification Number",
    collapsibleContent: (
      <span>
        An Employer Identification Number (EIN) is also known as a Federal Tax Identification Number (FEIN), and is used to identify a business entity. 
        Generally, businesses need an EIN. You may apply for an EIN in various ways, and now you may use the IRS SS4 application online.
      </span>
    ),
  },
  {
    id: 6,
    title: "Employer ID number",
    collapsibleContent: (
      <span>
        An Employer ID Number (EIN) is a nine digit number issued by the IRS. 
        The Employer ID Number is used by the IRS to identify the tax accounts of employers.
      </span>
    ),
  },
  {
    id: 7,
    title: "EIN application",
    collapsibleContent: (
      <span>
        In order to receive an Employer Identification Number an IRS EIN application must be filled out. 
        For an EIN application an IRS SS4 application online can be utilized or a hardcopy of the IRS SS4 form can be faxed directly to the IRS.
      </span>
    ),
  },
  {
    id: 8,
    title: "Apply EIN Online",
    collapsibleContent: (
      <span>
        Apply for your EIN Online with EIN Federal Tax ID and receive your EIN today by email. 
        Avoid the struggle of navigating federal forms systems and complicated application forms. 
        Alternatively you can also obtain your EIN Online from the IRS website www.irs.gov.
      </span>
    ),
  },
  {
    id: 9,
    title: "Get EIN Online",
    collapsibleContent: (
      <span>
        Get your EIN online with EIN Federal Tax ID and receive it today vai email. 
        Avoid the struggle of navigating federal forms systems and complicated application forms. 
        Alternatively you can also obtain your EIN Online from the IRS website www.irs.gov.
      </span>
    ),
  },
];

export const FAQ_GENERAL = [
  {
    id: 1,
    title: "What is an EIN (Tax ID) number?",
    renderCollapsibleContent: () => (
      <span>
        An EIN or employer identification number is a unique identification number that is assigned to a business entity so that it can easily be identified by the Internal Revenue Service (IRS).  
        An EIN number (also called a Tax ID) is commonly used for taxing and banking purposes and to register your business with the federal government.  
        Some of the common uses of an EIN number includes the ability to open a business bank account or line of credit, to hire employees or to apply for certain business licenses.
      </span>
    ),
  },
  {
    id: 2,
    title: "How long will it take to receive my EIN number?",
    renderCollapsibleContent: ({ rushPrice }) => (
      <>
        <span>
          Rush delivery will be completed same day if placed during business hours.
          Orders placed outside of business hours will be fulfilled next business day.
        </span>
        <br />
        <br />
        <ul>
          <li>Rush delivery service (same business day) – ${rushPrice}</li>
        </ul>
        <br />
        <span>
          Your EIN number will be received via Email and accessible on the <a>irs.ein-federal-tax-id.com</a> customer portal based on the turnaround time selected.  
          The EIN number will be immediately available for use to perform certain tasks such as opening bank accounts and registering for certain business licenses.  
          If you are trying to file a tax return online, or attempting another task which requires official documentation from the IRS, you will receive an official IRS confirmation notice via regular mail within 4 weeks.
        </span>
      </>
    ),
  },
  {
    id: 3,
    title: "Who are we?",
    renderCollapsibleContent: () => (
      <>
        <span>
          <Link href="/"><a>{window?.location.hostname}</a></Link> is a private filing and application assistance service designed to take the confusion out of applying online for an EIN number directly with the IRS.  
          We are not endorsed or affiliated with any government agency.  
          Completing an EIN number application (SS-4 form) directly with the IRS is complicated and often requires customers to spend hours deciphering the large government site to locate and complete the required form.  
          Clients use our application assistance service to avoid the headache and struggle that is often associated with navigating federal filing systems.  Spend more time building your business and less time at a government office or trying to navigate government websites.  
          Let our business filing experts do the work for you.
        </span>
        <br />
        <br />
        <span>
          Our service takes the confusion out of applying for an EIN number, saves you time (single-page application can be completed in as little as 5 minutes) and provides support for those who have questions along the way.  
          We understand this process can be confusing and created our company to simplify the application process and provide assistance along the way, giving your more time to focus on building your business.
        </span>
      </>
    ),
  },
  {
    id: 4,
    title: "How do I check the status of my application?",
    renderCollapsibleContent: () => (
      <span>
        You can check the status of your application by entering your Transaction ID directly on our Check Status page.
      </span>
    ),
  },
  {
    id: 5,
    title: "Why do clients choose our services?",
    renderCollapsibleContent: () => (
      <>
        <span>
          Completing the EIN number application (SS-4 form) on your own can be confusing and time consuming.  
          That is where we come in to help guide you through the process with our simplified application and to work alongside the IRS on your behalf, as your third-party designee.  
          In many cases, an error code is presented when submitting an application which requires the customer to call the responsible IRS department directly to get it sorted out before an EIN number can be issued.  
          Call times vary, however the average wait time is between 1 and 1.5 hours.  We will take the responsibility to review your application to ensure it has been completed properly before submission so you can obtain your EIN number in an expedited manner.
        </span>
        <br />
        <br />
        <span>
          We understand that you can work directly with the IRS to obtain your EIN number but that this process can be confusing, frustrating and time consuming.  
          Our service takes the confusion out of applying for an EIN number, saves you time (single-page application can be completed in as little as 5 minutes) and provides support for those who have questions along the way.
        </span>
        <br />
        <br />
        <span>
          Here is an example of the many services we provide to assist with the process of obtaining an EIN number:
        </span>
        <br />
        <br />
        <ul>
          <li>
            Simplified EIN number application (SS-4 form) – The average time to complete our simplified application is 5 min.
          </li>
          <li>
            Application review and error resolution before submitting to IRS – No more having to wait on hold (average wait time Is between 1 and 1.5 hrs) with the IRS to resolve an issue with your EIN application.
          </li>
          <li>
            24/7 online application submission – The IRS website only makes the EIN number application form available from 7 AM  to 10 PM EST, Monday through Friday.  
            <a>irs.ein-federal-tax-id.com</a> is always open and takes online applications according to your schedule.
          </li>
          <li>
            Customer support – Email and phone support from our dedicated business filing experts are available to guide you through the application process.
          </li>
          <li>
            Customer portal – Need to retrieve your EIN number or information from your SS-4 form? 
            We have a dedicated customer portal which you can access at any time to check the status of your application or retrieve your EIN number and information from your SS-4 form.
          </li>
          <li>
            Email and text notifications along the way – Take the guesswork out of your application status with our email and text notifications so you always know the status of your application.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    title: "What’s the difference between an EIN Number and Tax ID?",
    renderCollapsibleContent: () => (
      <span>
        At the Federal level, a “Tax ID Number” and an “EIN Number” are the same thing.  
        It is important to note that each state has its own Tax ID Number which is different than the Federal EIN or tax number.  
        An EIN Number is always at the federal level (sometimes called a FEIN – Federal EIN) and there are no state level EIN Numbers.
      </span>
    ),
  },
  {
    id: 7,
    title: "Who needs an EIN number?",
    renderCollapsibleContent: () => (
      <>
        <span>
          An EIN is required for many reasons for businesses, taxable entities and non-profit organizations. 
          If any of the follow apply to your business or entity you will need an EIN:
        </span>
        <br />
        <br />
        <ul>
          <li>
            Hiring Employees
          </li>
          <li>
            Operate your business as a Corporation or Partnership
          </li>
          <li>
            If you will file any of following tax returns: Employment, Excise or Alcohol, Tobacco and Firearms
          </li>
          <li>
            Have a Keogh Plan
          </li>
          <li>
            <span>
              If you’re involved with any of the following organizations/entities:
            </span>
            <br />
            <br />
            <ul>
              <li>
                Trusts (except certain grantor-owned revocable trusts)
              </li>
              <li>
                Estates
              </li>
              <li>
                Real Estate mortgage investment conduits
              </li>
              <li>
                Non-profit organizations
              </li>
              <li>
                Farmers’ cooperatives
              </li>
              <li>
                Plan Administrators
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 8,
    title: "How do I cancel my EIN Number order?",
    renderCollapsibleContent: () => (
      <span>
        If we have not yet delivered your EIN Number, you may contact us at any time via Email or phone and we will issue a full refund, no questions asked.
      </span>
    ),
  },
  {
    id: 9,
    title: "I’ve lost my new EIN and business information. How do I locate it?",
    renderCollapsibleContent: () => (
      <span>
        If you’ve misplaced your EIN Number or business information from the SS-4 application, you can access our customer portal at any time to retrieve the information.  
        The only information you need is your customer ID so we can confirm your identify.  
        You can also contact us via Email or phone and we can provide you with the requested information.
      </span>
    ),
  },
  {
    id: 10,
    title: "How can I ask questions about my application?",
    renderCollapsibleContent: () => (
      <>
        <span>
          You can contact us via Email <a href={`mailto:${email}?subject=Mail from Contact Us USA Taxid Site`}>{email}</a> or phone (1 424-340-0677).
          An agent is available via email or phone to answer all your questions. Our offices hours are M-F 9 AM – 5 PM PST.
        </span>
        <br />
        <br />
        <span>
          When submitting your email request via the contact form, please provide your Transaction ID so that your account can be easily located.  
          All tickets are replied within 24-hours during business days.
        </span>
      </>
    ),
  },
  {
    id: 11,
    title: "How do I cancel my EIN with the IRS?",
    renderCollapsibleContent: () => (
      <>
        <span>
          You will need to send a letter to the IRS with the company name and Taxpayer Identification Number of the current principal officer, general partner, grantor, owner, or trustor. 
          Be sure to include your complete name, EIN, mailing address and a note regarding your desire to cancel the EIN so that the department can correctly identify your IRS account.
        </span>
        <br />
        <br />
        <span>
          Mailing information is below:
        </span>
        <br />
        <br />
        <span>
          For entities located in – Connecticut, Delaware, District of Columbia, Illinois, Indiana, Kentucky, Maine, Maryland, Massachusetts, Michigan, 
          New Hampshire, New Jersey, New York, North Carolina, Ohio, Pennsylvania, Rhode Island, South Carolina, Vermont, Virginia, West Virginia, 
          or Wisconsin, the mailing address is:
        </span>
        <br />
        <br />
        <span>
          Internal Revenue Service Stop 343G Cincinnati, OH 45999 Attention: Entity Department
        </span>
        <br />
        <br />
        <span>
          For entities located in – Alabama, Alaska, Arkansas, Arizona, California, Colorado, Florida, Georgia, Hawaii, Idaho, Iowa, Kansas, Louisiana, 
          Minnesota, Mississippi, Missouri, Montana, Nebraska, Nevada, New Mexico, North Dakota, Oklahoma, Oregon, South Dakota, Tennessee, Texas, 
          Utah, Washington, Wyoming, or any place outside of the United States, the mailing address is:
        </span>
        <br />
        <br />
        <span>
          Internal Revenue Service M/S 6273 Ogden, UT 84201 Attention: Entity Department
        </span>
        <br />
        <br />
        <span>
          The IRS will send a letter confirming the receipt of the cancellation. 
          If the entity has not received the confirmation letter within 60 days, 
          you should fax a copy of the original letter to the office that services the entity state.
        </span>
      </>
    ),
  },
];

export const FAQ_BILLING = [
  {
    id: 1,
    title: "What is your refund policy?",
    collapsibleContent: (
      <>
        <span>
          If we have started to process your order but not yet completed the preparation and filing of your EIN Number, we will refund your payment in full.
        </span>
        <br />
        <br />
        <span>
          If we do not provide the EIN within the selected delivery timeline we will issue a full refund upon request unless the delay was due to you entering incorrect information (i.e. incorrect social security number, business name, business address, etc.). 
          We will contact you immediately for the correct information and if you provide the correct information and we do not provide the EIN within the requested delivery timeline, a full refund will be issued upon request.
        </span>
        <br />
        <br />
        <span>
          All refund requests must be made by contacting customer service. 
          All refunds will be issued to the original credit card used and will be processed within seven business days.  
          Additionally, if the payment method used to pay for the transaction is no longer valid, canceled or stops working for any reason, 
          we will not be responsible if you cannot receive reimbursement for reasons of closure, 
          cancellation or any reason to limit the operation of payment method.
        </span>
        <br />
        <br />
        <span>
          Once your EIN number has been issued or service has been obtained from the IRS on your behalf a refund is not available.
        </span>
      </>
    ),
  },
  {
    id: 2,
    title: "How long does it take for a refund to post on my bank or credit card statement?",
    collapsibleContent: (
      <>
        <span>
          Generally, refunds take 3-4 business days to post back to your account.  
          However, this may take up to 10 days depending on the bank or credit card issuer.
        </span>
        <br />
        <br />
        <span>
          Please note, if the payment method used to pay for the transaction is no longer valid, 
          canceled or stops working for any reason, we will not be responsible if you cannot receive reimbursement for reasons of closure, 
          cancellation or any reason to limit the operation of payment method.
        </span>
      </>
    ),
  },
  {
    id: 3,
    title: "Why was I charged twice?",
    collapsibleContent: (
      <>
        <span>
          This can sometimes happen if you hit the back button or hitting Pay Now multiple times when submitting your application.  
          Please contact us so that we may issue a full refund for the duplicate transaction.
        </span>
        <br />
        <br />
        <span>
          If you still need more assistance, please contact us directly via the contact form.
        </span>
      </>
    ),
  },
  {
    id: 4,
    title: "What happens to the information if I didn’t submit a payment?",
    collapsibleContent: (
      <>
        <span>
          If you do not complete the application process by submitting a payment, the application is not processed by our company.
        </span>
        <br />
        <br />
        <span>
          Our website is Norton Secured and any information you submit is also secured using SSL encryption technology to add an extra layer of protection.
        </span>
      </>
    ),
  },
  {
    id: 5,
    title: "Can I receive a refund after submitting a credit card dispute?",
    collapsibleContent: (
      <>
        <span>
          Please contact us directly regarding a refund or if you have any questions or concerns regarding our service.  
          We have customer service agents available and can be reached via phone or email.
        </span>
        <br />
        <br />
        <span>
          If you contact your bank directly to file a dispute, this will take up to 21 days for the funds to return to your account and will require additional paperwork for you to complete.  
          If you have already filed a dispute, we kindly request that you cancel it and contact us for a refund directly.
        </span>
        <br />
        <br />
        <span>
          We issue refunds according to our refund policy. 
          Please see our refund policy located in our Terms of Use.
        </span>
        <br />
        <br />
        <span>
          However, if your card was lost or stolen and an unknown person has used your card to purchase a service on our website; 
          please contact your bank’s fraud department directly so that they may issue an investigation.
        </span>
      </>
    ),
  },
  {
    id: 6,
    title: "Why do I have multiple transactions on my bank statement?",
    collapsibleContent: (
      <>
        <span>
          Multiple transactions may appear on your bank statement if there was a failed transaction or if the application was submitted more than once.
        </span>
        <br />
        <br />
        <span>
          These charges should appear on your statement as pending and the funds should be released by your bank within 3-4 business days. 
          However, this may take up to 10 days depending on the bank or credit card issuer.
        </span>
        <br />
        <br />
        <span>
          If you still need more assistance, please contact us directly via email or phone.
        </span>
      </>
    ),
  },
];
